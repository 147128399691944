import * as React from 'react'
import { Link } from "gatsby"
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChargingStation, faStore, faLightbulb, faQuestionCircle, faCookieBite, faHeart, faAsterisk } from '@fortawesome/free-solid-svg-icons'

const OmPage = () => {
	return (
		<Layout pageTitle="Laddify">
			<p className="intro">
				<b><i>Hitta rätt laddplats snabbt &amp; enkelt!</i></b>
			</p>

			<div className="buttons">
				<Link to="/om/natverk">
					<FontAwesomeIcon icon={faChargingStation} />
					<p>Nätverk</p>
				</Link>
				<Link to="/om/narservice">
					<FontAwesomeIcon icon={faStore} />
					<p>Närservice</p>
				</Link>
				<Link to="/om/forslag">
					<FontAwesomeIcon icon={faLightbulb} />
					<p>Förslag &amp;<br/>feedback</p>
				</Link>
				<Link to="/om/fragor-och-svar">
					<FontAwesomeIcon icon={faQuestionCircle} />
					<p>Frågor &amp; svar</p>
				</Link>
				<Link to="/om/kakor">
					<FontAwesomeIcon icon={faCookieBite} />
					<p>Kakor</p>
				</Link>
				<Link to="/om/tack">
					<FontAwesomeIcon icon={faHeart} />
					<p>Tack</p>
				</Link>
				<Link to="/om/villkor">
					<FontAwesomeIcon icon={faAsterisk} />
					<p>Villkor &amp;<br/>personuppgifter</p>
				</Link>
			</div>



			<h2>Om Laddify</h2>
			<p>
				Med hjälp av Laddify kan förare till Sveriges över 300 000 laddbara fordon enkelt hitta bland tusentals publika laddstationer i landet. Varmt välkommen!
			</p>
			<p>
				Det finns en hel del olika verktyg/tjänster, redan innan Laddify, som listar laddplatser från flera nätverk/leverantörer för att du ska slippa titta runt i massa olika appar från varje laddnätverk. Skaparen bakom Laddify, <a href="https://iterik.se" target="_blank" rel="noreferrer">Erik</a>, tyckte dock att de verktygen antingen haltade i datakvalitet, gränssnitt eller saknade ordentliga möjligheter till filtrering. Därför bestämde han sig för att göra något åt saken, först som webbsida och sedan framöver även som app till iOS och Android.
			</p>
			<p>
				Laddify.se är just nu i beta-version och all konstruktiv <Link to="/om/forslag">feedback</Link> välkomnas!
			</p>



			<h2>Stötta Laddify</h2>
			<ul>
				<li><b>Ekonomiskt</b> kan du stötta genom att klicka på annonser och göra köp där igenom. På så sätt får Laddify provision och kan fortsätta underhålla och vidareutveckla tjänsten.</li>
				<li><b>Innehållsmässigt</b> kan du stötta genom att <Link to="/om/forslag">tipsa</Link> Laddify om laddstationer som saknas eller kring felaktig informaton. Du kan också genom att publicera 360-bilder på Google Street View (<a href="https://play.google.com/store/apps/details?id=com.google.android.street&hl=sv" target="_blank" rel="noreferrer">Android</a> &bull; <a href="https://apps.apple.com/us/app/google-street-view/id904418768" target="_blank" rel="noreferrer">iOS</a>) bidra till att nyare bilder på laddstationerna visas. Samt att du kan checka in på laddstionerna och sprida information till andra elbilsförare.</li>
			</ul>


			<br/><br/><br/><br/><br/><br/><br/>

		</Layout>
	)
}

export default OmPage